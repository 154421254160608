import SpinnerContainer from 'common/components/SpinnerContainer';
import React, { useEffect } from 'react';
import TotalTvls from 'common/components/TotalTvls';
import { usePaginatedFarms } from 'common/hooks/usePaginatedFarms';
import { usePaginatedMonitorVaults } from 'common/hooks/usePaginatedMonitorVaults';

type Props = {};

const TvlContainer: React.FC<Props> = () => {
  // Use our pagination hooks for the data types
  const { 
    farms, 
    loading: loadingFarms, 
    error: errorFarms,
    refreshAll: refreshFarms
  } = usePaginatedFarms();
  
  const {
    vaults,
    loading: loadingVaults,
    error: errorVaults,
    refreshAll: refreshVaults
  } = usePaginatedMonitorVaults(false);  // We don't need rebalance-only filter here
  
  // Load all data on initial component mount
  useEffect(() => {
    const loadAllData = async () => {
      // Load all data in parallel
      await Promise.all([
        refreshFarms(),
        refreshVaults()
      ]);
    };
    
    loadAllData();
  }, []);

  // Combine all errors to show
  const anyError = errorFarms || errorVaults;
  if (anyError) {
    console.error(anyError);
  }

  // Check if any data is still loading
  const isLoading = loadingFarms || loadingVaults;

  return (
    <SpinnerContainer isLoading={isLoading}>
      <TotalTvls 
        vaults={vaults} 
        farms={farms} 
      />
    </SpinnerContainer>
  );
};

export default TvlContainer;
